import { graphql } from 'gatsby';
import React from 'react';
import { Button, Tooltip } from 'reactstrap';

import OrderWrapper from '../components/orderWrapper';
import IconHelp from '../icons/help';

export default class Order extends React.Component {
  state = {
    ...this.props.location.state,
    step: 1,
  }

  toggleTooltip(id) {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen ? id : false,
    })
  }

  render() {
    console.log(this.state)

    const serviceID = this.state.service && this.state.service.id

    return (
      <OrderWrapper
        state={this.state}
        isNextStepDisabled={serviceID ? false : true}
      >
        <div className="text-center mb-4">
          <h2 className="h1">Zvolte službu</h2>
        </div>

        <div className="row justify-content-center mt-n4">
          {this.props.data.allWordpressWpNoveSluzby.edges.map(({ node }) => (
            <div
              key={node.wordpress_id}
              className="col-md-6 col-xl-5 d-flex pt-4"
            >
              <article
                className={`card flex-grow-1 w-100 ${
                  serviceID === node.wordpress_id
                    ? "border-dark"
                    : "border-white"
                }`}
              >
                <div className="card-body flex-grow-0">
                  <header className="rad-title mb-3 mb-lg-4 d-flex flex-column">
                    <h3 className="mb-0 h2">{node.acf.s_o_tucny_nadpis}</h3>
                    <p className="font-weight-normal mb-0">
                      {node.acf.s_o_normalni_nadpis}
                      <IconHelp
                        className="position-relative d-inline-block ml-2"
                        style={{ zIndex: 2, cursor: "help" }}
                        id={`tooltip-${node.wordpress_id}`}
                      />
                    </p>
                  </header>
                  <Tooltip
                    placement="top"
                    isOpen={
                      this.state.tooltipOpen === `tooltip-${node.wordpress_id}`
                    }
                    target={`tooltip-${node.wordpress_id}`}
                    toggle={() =>
                      this.toggleTooltip(`tooltip-${node.wordpress_id}`)
                    }
                  >
                    <div
                      className="p-2 text-left"
                      dangerouslySetInnerHTML={{
                        __html: node.acf.s_o_napoveda,
                      }}
                    />
                  </Tooltip>
                  <div
                    className="card-text mb-n3"
                    dangerouslySetInnerHTML={{
                      __html: node.acf.s_o_popis,
                    }}
                  />
                </div>

                <div className="card-body flex-grow-0 mt-auto">
                  <Button
                    color={
                      serviceID === node.wordpress_id ? "dark" : "secondary"
                    }
                    className="w-100 stretched-link"
                    onClick={() =>
                      this.setState({
                        service: {
                          id: node.wordpress_id,
                          name: node.acf.s_o_tucny_nadpis,
                        },
                      })
                    }
                  >
                    {serviceID === node.wordpress_id ? "Vybráno" : "Vybrat"}
                  </Button>
                </div>
                {node.acf.s_o_tag && (
                  <p
                    className="position-absolute h5 margin-0 font-weight-light"
                    style={{ top: "10px", right: "-1rem", lineHeight: 1 }}
                  >
                    <span className="badge badge-info rounded-0 rounded-left p-2">
                      {node.acf.s_o_tag}
                    </span>
                    <span
                      className="position-absolute"
                      style={{
                        display: "block",
                        width: 0,
                        height: 0,
                        top: "100%",
                        right: 0,
                        borderStyle: "solid",
                        borderWidth: "0.5rem 1rem 0 0",
                        borderColor:
                          "black transparent transparent transparent",
                        borderTopColor: "var(--dark)",
                      }}
                    ></span>
                  </p>
                )}
              </article>
            </div>
          ))}
        </div>
      </OrderWrapper>
    )
  }
}

export const query = graphql`
  query {
    allWordpressWpNoveSluzby(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          wordpress_id
          wordpress_parent
          acf {
            s_o_tucny_nadpis
            s_o_normalni_nadpis
            s_o_popis
            s_o_napoveda
            s_o_tag
          }
        }
      }
    }
  }
`
